<template>
    <div class="register_box">
        <loading-component></loading-component>
        <div class="register_title">
            用户注册
        </div>
        <van-form>
            <van-field
                v-model="driver.name"
                name="name"
                label="姓名"
                placeholder="请输入真实姓名"
                :rules="[{ required: true, message: '请填写真实姓名' }]"
            />
            <number-keyboard-component
                name="icard"
                label="身份证号"
                extra_key="X"
                right_icon=""
                placeholder="请输入身份证号"
                :fvalue="driver.icard"
                @on_input="confirm_icard"
            ></number-keyboard-component>
            <van-cell title="身份证正面">
                <template #label>
                    <uploader-component
                        :farr="driver.icard_file_a"
                        url="car_driver/icard_file_a"
                        :max_count="1"
                        @update_img="update_icard_file_a"
                    ></uploader-component>
                </template>
            </van-cell>
            <van-cell title="身份证反面">
                <template #label>
                    <uploader-component
                        :farr="driver.icard_file_b"
                        url="car_driver/icard_file_b"
                        :max_count="1"
                        @update_img="update_icard_file_b"
                    ></uploader-component>
                </template>
            </van-cell>
            <van-cell title="驾驶证主页">
                <template #label>
                    <uploader-component
                        :farr="driver.jscard_file_a"
                        url="car_driver/jscard_file_a"
                        :max_count="1"
                        @update_img="update_jscard_file_a"
                    ></uploader-component>
                </template>
            </van-cell>
            <van-cell title="驾驶证副页">
                <template #label>
                    <uploader-component
                        :farr="driver.jscard_file_b"
                        url="car_driver/jscard_file_b"
                        :max_count="1"
                        @update_img="update_jscard_file_b"
                    ></uploader-component>
                </template>
            </van-cell>
            <van-field
                v-model="driver.phone"
                name="phone"
                label="手机号"
                placeholder="请输入手机号"
                :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <van-field
                v-model="driver.yzm"
                name="yzm"
                label="验证码"
                placeholder="请输入验证码"
            >
                <template #right-icon>
                    <van-button v-if="!sendPhoneTime" block type="info" size="small" @click="get_yzm">获取验证码</van-button>
                    <van-button v-else block type="info" size="small" disabled>重新获取({{ sendPhoneTime }})</van-button>
                </template>
            </van-field>
            <div class="register_button">
                <van-button round block type="primary" @click="onRegister">注册</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>

import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { lf_login_yzm_request,lf_register_request } from '@/network/user'

export default {
    name: 'RegisterView',
    data() {
        return {
            driver: { 
                name: '',
                phone: '',
                icard: '',
                icard_file_a: [],
                icard_file_b: [],
                jscard_file_a: [],
                jscard_file_b: []
            },
            sendPhoneTime: 0
        }
    },
    computed: {},
    methods: {
        confirm_icard(e) {
            this.driver.icard = e
        },
        update_icard_file_a(arr) {
            this.driver.icard_file_a = arr
        },
        update_icard_file_b(arr) {
            this.driver.icard_file_b = arr
        },
        update_jscard_file_a(arr) {
            this.driver.jscard_file_a = arr
        },
        update_jscard_file_b(arr) {
            this.driver.jscard_file_b = arr
        },
        get_yzm() {
            this.$store.commit('true_loading')
            const identity = this.$store.state.identity
            this.sendPhoneTime = 60
            lf_login_yzm_request({phone: this.driver.phone,identity: identity})
                    .then((s) => {
                        if (s.status === 0) {
                            this.$toast.success('验证码已发送')
                            const changeSendPhoneTime = setInterval(() => {
                                if (this.sendPhoneTime) {
                                    this.sendPhoneTime--
                                } else {
                                    clearInterval(changeSendPhoneTime)
                                }
                            }, 1000)
                        } else {
                            this.$toast.fail(s.msg)
                            this.sendPhoneTime = 0
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg)
                        this.sendPhoneTime = 0
                    })
                    .finally(() => this.$store.commit('false_loading'))
        },
        onRegister() {
            this.$store.commit('true_loading')
            this.driver.identity = this.$store.state.identity
            lf_register_request(this.driver)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$toast.success('登录成功');
                            this.$router.replace('/')
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => this.$store.commit('false_loading'))
        },
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        LoadingComponent,
        NumberKeyboardComponent,
        UploaderComponent
    },
}
</script>
<style scoped>
.register_box{
    width: 375px;
    border-radius: 3px;
    position: absolute;
    /* top: 5%; */
    /* transform: translate(-50%,-50%); */
    opacity: 0.9;
}
.register_title {
    margin: 0.5rem 0;
    font-size: 0.5rem;
    text-align: center;
    font-weight: bold;
}
.register_button {
    margin: 0.5rem;
}
</style>